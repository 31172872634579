import React from "react";
import "./courses.css";

const coursesCard = [
  {
    id: 1,
    cover: "../images/courses/c1.png",
    coursesName: "MERN Stack Development",
    courTeacher: [
      {
        dcover: "./images/back.webp",
        name: "by Lead Instructor",
        totalTime: "4 months (Monday - Friday, 9:00 AM - 1:00 PM)",
      },
    ],
    priceAll: "₹45,000 All Course",
    pricePer: "₹11,250 per month",
    features: [
      "Full-stack JavaScript development",
      "Real-world project experience",
      "Industry-standard tools and practices",
      "Job placement assistance",
    ],
  },
  {
    id: 2,
    cover: "../images/courses/c2.png",
    coursesName: "DevOps Engineering",
    courTeacher: [
      {
        dcover: "./images/back.webp",
        name: "by Lead Instructor",
        totalTime: "3 months (Monday - Friday, 2:00 PM - 6:00 PM)",
      },
    ],
    priceAll: "₹50,000 All Course",
    pricePer: "₹16,667 per month",
    features: [
      "AWS/Azure cloud platforms",
      "Docker and Kubernetes",
      "Jenkins and GitLab CI",
      "Infrastructure as Code",
    ],
  },
  {
    id: 3,
    cover: "../images/courses/c3.png",
    coursesName: "Software Testing",
    courTeacher: [
      {
        dcover: "./images/back.webp",
        name: "by Lead Instructor",
        totalTime: "3 months (Monday - Friday, 10:00 AM - 2:00 PM)",
      },
    ],
    priceAll: "₹35,000 All Course",
    pricePer: "₹11,667 per month",
    features: [
      "Manual testing fundamentals",
      "Selenium automation",
      "API testing",
      "Performance testing",
    ],
  },
  {
    id: 4,
    cover: "../images/courses/c4.png",
    coursesName: "Python Full Stack Development",
    courTeacher: [
      {
        dcover: "./images/back.webp",
        name: "by Lead Instructor",
        totalTime: "4 months (Monday - Friday, 3:00 PM - 7:00 PM)",
      },
    ],
    priceAll: "₹40,000 All Course",
    pricePer: "₹10,000 per month",
    features: [
      "Python programming fundamentals",
      "Django web framework",
      "Database management",
      "RESTful API development",
    ],
  },
];

const CoursesCard = () => {
  return (
    <>
      <section className="coursesCard">
        <div className="container grid2">
          {coursesCard.map((val) => (
            <div className="items" key={val.id}>
              <div className="content flex">
                <div className="left">
                  <div className="img">
                    <img src={val.cover} alt={val.coursesName} />
                  </div>
                </div>
                <div className="text">
                  <h1>{val.coursesName}</h1>
                  <div className="rate">
                    <i className="fa fa-star"></i>
                    <i className="fa fa-star"></i>
                    <i className="fa fa-star"></i>
                    <i className="fa fa-star"></i>
                    <i className="fa fa-star"></i>
                    <label htmlFor="">(5.0)</label>
                  </div>
                  <div className="details">
                    {/* {val.courTeacher.map((details, index) => (
                      <div key={index}>
                        <div className="box">
                          <div className="dimg">
                            <img src={details.dcover} alt="" />
                          </div>
                          <div className="para">
                            <h4>{details.name}</h4>
                          </div>
                        </div>
                        <span>{details.totalTime}</span>
                      </div>
                    ))} */}
                  </div>
                  {/* Added Features Section */}
                  <div className="features">
                    <h4>Key Features:</h4>
                    <ul>
                      {val.features.map((feature, index) => (
                        <li key={index}>{feature}</li>
                      ))}
                    </ul>
                  </div>
                  {/* Added Pricing Section */}
                  <div className="pricing">
                    <p className="price-all">{val.priceAll}</p>
                    <p className="price-per">{val.pricePer}</p>
                  </div>
                </div>
              </div>
              <button className="outline-btn">ENROLL NOW !</button>
            </div>
          ))}
        </div>
      </section>
    </>
  );
};

export default CoursesCard;
